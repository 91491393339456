
import {defineComponent} from 'vue';
import FaIconButton from '@/components/button/FaIconButton.vue';
import NavActionGroup from '@/components/nav/NavActionGroup.vue';
import NavActionItem from '@/components/nav/NavActionItem.vue';
import NavActionFaIcon from '@/components/nav/NavActionFaIcon.vue';
import {useStore} from 'vuex';
import useSpider from '@/components/spider/spider';
import RunSpiderDialog from '@/components/spider/RunSpiderDialog.vue';

export default defineComponent({
  name: 'SpiderDetailActionsCommon',
  components: {
    NavActionFaIcon,
    FaIconButton,
    NavActionGroup,
    NavActionItem,
    RunSpiderDialog,
  },
  setup() {
    // store
    const ns = 'spider';
    const store = useStore();

    const onRun = () => {
      store.commit(`${ns}/showDialog`, 'run');
    };

    return {
      ...useSpider(store),
      onRun,
    };
  },
});
