
import {defineComponent, onBeforeMount, ref, watch} from 'vue';
import {FILE_UPLOAD_MODE_DIR, FILE_UPLOAD_MODE_FILES} from '@/constants/file';
import {ElUpload} from 'element-plus/lib/el-upload/src/upload.type';
import {UploadFile} from 'element-plus/packages/upload/src/upload.type';
import Button from '@/components/button/Button.vue';
import Tag from '@/components/tag/Tag.vue';
import {plainClone} from '@/utils/object';

export default defineComponent({
  name: 'FileUpload',
  components: {
    Tag,
    Button,
  },
  props: {
    mode: {
      type: String,
    },
    getInputProps: {
      type: Function,
    },
    open: {
      type: Function,
    },
  },
  emits: [
    'mode-change',
    'files-change',
  ],
  setup(props: FileUploadProps, {emit}) {
    const modeOptions: FileUploadModeOption[] = [
      {
        label: 'Folder',
        value: FILE_UPLOAD_MODE_DIR,
      },
      {
        label: 'Files',
        value: FILE_UPLOAD_MODE_FILES,
      },
    ];
    const internalMode = ref<string>();

    const uploadRef = ref<ElUpload>();

    const dirPath = ref<string>();

    watch(() => props.mode, () => {
      internalMode.value = props.mode;
      uploadRef.value?.clearFiles();
    });

    const onFileChange = (file: UploadFile, fileList: UploadFile[]) => {
      emit('files-change', fileList.map(f => f.raw));
    };

    const clearFiles = () => {
      uploadRef.value?.clearFiles();
    };

    const onModeChange = (mode: string) => {
      emit('mode-change', mode);
    };

    onBeforeMount(() => {
      const {mode} = props;
      internalMode.value = mode;
    });

    const dirInfo = ref<FileUploadInfo>();

    const setInfo = (info: FileUploadInfo) => {
      dirInfo.value = plainClone(info);
    }

    const resetInfo = (info: FileUploadInfo) => {
      dirInfo.value = undefined;
    };

    return {
      uploadRef,
      FILE_UPLOAD_MODE_FILES,
      FILE_UPLOAD_MODE_DIR,
      modeOptions,
      internalMode,
      dirPath,
      onFileChange,
      clearFiles,
      onModeChange,
      dirInfo,
      setInfo,
      resetInfo,
    };
  },
});
